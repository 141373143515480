html,
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

img {
  pointer-events: none;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block
}

#root {
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  background-image: url('./imgs/background.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  padding-top: 100px;
  min-height: 89vh;
}

.nav {
  display: flex;
  flex-direction: column;
  background-color: #252423;
  min-height: 50px;
  width: -webkit-fill-available;
  width: 100%;
  font-size: 30px;
  color: #D0DAEC;
  overflow: hidden;
  position: fixed;
  z-index: 100;
}

.nav-wrapper {
  display: flex;
  flex: 1 0 auto;
  gap: 20px;
  padding: 0 10px;
}

.nav-wrapper h1 {
  flex: 1 0 auto;
  font-size: 1em;
  text-align: center;
}

.nav-wrapper-2 {
  display: flex;
  flex: 1 0 auto;
  gap: 20px;
  justify-content: center;
}

.nav-wrapper-2 h4 {
  font-size: 0.5em;
  padding-top: 15px;
}

.nav-wrapper a {
  display: flex;
  padding-top: 5px;
  text-decoration: none;
  color: #D0DAEC;
}

.nav-wrapper span {
  display: flex;
  padding-top: 10px;
  text-decoration: none;
  color: #D0DAEC;
}

.nav-wrapper-2 .active {
  border-bottom: 1px solid white;
  padding-bottom: 5px;
}

.section {
  text-align: center;
}

.time-header {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 30px;
  margin: 20px 0px 30px 0px;
}

.time-header p {
  border: 1px #4682BD solid;
  border-radius: 5px;
  background-color: #4682BD;
  font-weight: bold;
  color: #C1D6ED;
  padding: 0 5px;
  text-transform: uppercase;
}

.time-header span {
  font-size: 30px;
  color: #5FAFDB;
  text-transform: uppercase;
  text-shadow: 1px 1px 5px black,
    -1px -1px 5px black,
    1px -1px 5px black,
    -1px 1px 5px black;
}

.container {
  position: relative;
  min-height: 100vh;
}

.main {
  /* height: 100%;
  display: flex;
  flex-direction: column; */
  font-size: 20px;
  color: white;
}

.main-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 5% 50px 5%;
  gap: 5%;
  justify-content: center;
}

.tracker {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  margin-bottom: 20px;
  overflow: hidden;
  text-align: center;
  height: fit-content;
  width: 350px;
  min-width: 350px;
}

.daily {
  border: rgb(218, 141, 0) solid 3px;
}

.weekly {
  border: #35579c solid 3px;
}

.tracker-wrapper {
  background-color: rgba(0, 0, 0, 0.50);
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.daily .tracker-header {
  background-color: rgb(218, 141, 0);
  font-size: 25px;
  padding: 5px;
}

.weekly .tracker-header {
  background-color: #35579c;
  font-size: 25px;
  padding: 5px;
}

.tracker-header span {
  display: block;
  margin-top: 5px;
  font-size: 14px;
}

.collapsed .collapse-icon:after {
  transform: none;
}

.collapse-icon:after {
  content: '\276F';
  transform: rotate(90deg);
  font-size: 25px;
  color: white;
  float: right;
  /* margin-top: 4px; */
  margin-right: 5px;
  transition: transform 0.5s ease-in-out;
}

/*
.daily>div:not(.tracker-wrapper) {
  border-bottom: rgb(218, 141, 0) solid 3px;
}

.weekly>div:not(.tracker-wrapper) {
  border-bottom: #35579c solid 3px;
}
*/

.overlay-wrapper {
  cursor: pointer;
  transition: transform .2s;
  will-change: transform;
}

.overlay-wrapper:hover {
  transform: translateZ(0) scale(1.03, 1.03);
}

.maple .daily .overlay-wrapper:hover .strike span {
  color: #9b8a62;
}

.maple .weekly .overlay-wrapper:hover .strike span {
  color: #6d8594;
}

.maple .daily .overlay-wrapper:hover span {
  color: #ffe4a3;
}

.maple .weekly .overlay-wrapper:hover span {
  color: #bce5ff;
}

/*
.daily .item {
  border-bottom: rgb(218, 141, 0) solid 1px;
}

.weekly .item {
  border-bottom: #35579c solid 1px;
}
*/

.item {
  padding: 5px 10px 5px 0px;
  /*border-bottom: darkgray solid 1px;*/
  display: flex;
  align-items: center;
  overflow: hidden;
}

.item img {
  margin: 0 10px;
  box-shadow: 1px 1px 3px rgb(104, 104, 104),
    -1px -1px 3px rgb(104, 104, 104),
    1px -1px 3px white,
    -1px 1px 3px white;
}

.item span {
  text-align: left;
  text-shadow: 1px 1px 5px black,
    -1px -1px 5px black,
    1px -1px 5px black,
    -1px 1px 5px black;
}

.completed {
  position: absolute;
  height: 50px;
  margin-left: -171px;
  margin-top: -1px;
  width: 50px;
}

@keyframes strike {
  from {
    text-decoration-color: transparent;
  }

  to {
    text-decoration-color: red;
  }
}

.strike {
  text-decoration: line-through 1px red;
  animation: strike 150ms linear;
}

.strike span {
  color: #aaaaaa;
}

.reset-timer {
  border: 1px rgb(179, 0, 0) solid;
  border-radius: 10px;
  background-color: rgba(255, 43, 43, 0.5);
  font-weight: bold;
  color: #ffffff;
  padding: 5px 15px;
  text-transform: uppercase;
  width: fit-content;
  margin: auto;
  text-shadow: 1px 1px 1px black,
    -1px -1px 1px black,
    1px -1px 1px black,
    -1px 1px 1px black;
}

.reset-timer span {
  text-transform: none;
}

.let-there-be-light {
  overflow: auto;
  opacity: 1;
  transition: opacity 250ms linear;
}

.close-the-blinds {
  overflow: hidden;
  opacity: 0.25;
  transition: visibility 0s 250ms, opacity 250ms linear;
}

#SettingsModal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  width: 60%;
  height: 70%;
  border: 3px solid #425490;
  padding: 10px;
  left: 0;
  right: 0;
  top: 15%;
  margin-left: auto;
  margin-right: auto;
  min-width: 50vw;
  overflow: overlay;
  z-index: 100000;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 100ms linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 150ms, opacity 150ms linear;
}

.hidden-item {
  display: none;
}

.no-click {
  pointer-events: none;
}

#SettingsModal.visible {
  transition: opacity 300ms linear;
}

#SettingsModal h1 {
  text-align: center;
  font-size: 25px;
}

.setting-toggles {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.setting-item {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.setting-item p,
.setting-item input {
  display: inline;
}

.setting-item option {
  font-weight: bold;
}

#settings {
  cursor: pointer;
  font-size: 25px;
  position: fixed;
  right: 15px;
}

#settings:hover {
  color: rgb(165, 165, 165);
}

#SettingsModal select {
  background-color: rgb(206 206 206);
  min-width: 250px;
  width: 100%;
  border: 1px solid black;
  min-height: 175px;
  font-size: 16px;
  min-height: 205px;
}

.task-selecton {
  display: flex;
  flex-direction: row;
  gap: 15px;
  text-align: center;
  justify-content: center;
}

.reset-btn {
  border: 2px solid rgb(173, 0, 0);
  background-color: rgb(212, 0, 0);
  padding: 2px 5px;
  margin-top: -3px;
  border-radius: 3px;
}

.reset-btn:hover {
  border: 2px solid rgb(173, 0, 0);
  background-color: rgb(151, 0, 0);
}

.selection-btn {
  border: 2px solid rgb(110, 110, 255);
  background-color: rgb(110, 110, 255);
  width: 20%;
  padding: 2px 0;
  border-radius: 3px;
}

.selection-btn:hover {
  border: 2px solid rgb(110, 110, 255);
  background-color: rgb(61, 61, 255);
}

.settings-box {
  margin-top: 2em;
  border: 1px solid rgb(255, 255, 255);
  padding: 10px;
}

.settings-box h3 {
  display: table;
  margin: -28px auto -8px 0px;
  background-color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: bold;
  padding: 0 10px;
}

#TaskSelect {
  width: 100%;
}

.close {
  font-size: 25px;
  float: right;
  margin-top: -35px;
  cursor: pointer;
}

.close:hover {
  color: rgb(173, 173, 173);
}

#footer {
  text-align: center;
  color: white;
  height: 60px;
  background-color: #0E1E31;
  width: 100%;
  position: absolute;
  bottom: 0;
}

#footer p {
  padding-top: 20px;
}

#footer i {
  font-size: 14px;
}

#footer .fas:not(a > .fas) {
  font-weight: 100;
}

#footer a {
  font-weight: 900;
  color: white;
}

.noscroll {
  overscroll-behavior: contain;
  overflow: hidden;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  #SettingsModal {
    height: 70%;
    top: 15%;
    width: 60vw;
  }

  #SettingsModal select {
    width: 100%;
    font-size: 16px;
  }
}

@media only screen and (max-width: 851px) {
  #SettingsModal {
    height: 70%;
    top: 15%;
  }

  #SettingsModal select {
    width: 100%;
    font-size: 16px;
  }

  .task-selecton {
    flex-direction: column;
  }
}

@media only screen and (max-width: 546px) {
  #SettingsModal {
    height: 75%;
    top: 15%;
    width: 90vw;
    min-width: 290px;
  }

  #SettingsModal select {
    width: 100%;
    font-size: 16px;
  }
}

label {
  color: black;
}

.multiselect-dropdown {
  padding: 0;
  background-color: #eaeaea;
}

.multiselect-dropdown-list-wrapper {
  text-align: left;
  background: #eaeaea;
}

.multiselect-dropdown-list-wrapper .multiselect-dropdown-search {
  width: 97% !important;
  padding: 5px 0px 5px 5px;
  background-color: #eaeaea;
}

.multiselect-dropdown span.maxselected {
  width: 80%;
}

.multiselect-dropdown span.optext {
  background-color: gray;
  float: left;
  margin: 2px;
}